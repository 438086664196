
* {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    /*padding: 0;*/
    /*margin: 0;*/
}

.my-2.closed {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
}

.my-2.open {
    max-height: 500px; /* Adjust the value according to your content */
    overflow: hidden;
    transition: max-height 0.3s ease-in;
}


.slides-container {
    display: flex;
    width: 100%;
    overflow: hidden;
    transition: transform 0.5s ease; /* Add transition for smooth sliding */
}

.slide {
    flex: 0 0 100%;
    opacity: 0;
    transition: opacity 0.5s ease; /* Add transition for smooth fade */
}

.current-slide {
    opacity: 1;
}



.control-buttons {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.prev,
.next {
    margin: 0 10px;
}

.prev button,
.next button {
    background: none;
    border: none;
    cursor: pointer;
}


.box-card{
    border-radius: 12px 12px 12px 12px;
    box-shadow: 0 1px 1px #e7e7e7;
}



.current-slide {
    opacity: 1;
}


@font-face {
    font-family: 'TMoney';
    src: url('/public/assets/font/tmoney-main/TmoneyRoundWind-ExtraBold.woff2') format('woff2'),
    url('/public/assets/font/tmoney-main/TmoneyRoundWind-ExtraBold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.Tmoney{
    font-family: 'TMoney', sans-serif;
}






.h-img-banner-prev{
    height: 280px;
}

.bg-img-cloud {
    background-image: url("/public/assets/img/cloud-img.svg");
    background-size: cover;
    background-position: center;
}

.bg-img-cloud-rev {
    background-image: url("/public/assets/img/cloud-img-rev.svg");
    background-size: cover;
    background-position: center;
}

/*.shadow{*/
/*    background: rgba(42, 42, 42, 0.34);*/
/*    position: absolute; top: 0;*/
/*    bottom: 0;*/
/*    left: 0;*/
/*    right: 0;*/
/*    width: 100%;*/
/*}*/

.shadow-n {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.58) 100%);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
}



.radius-8{
    border-radius: 8px;
}

.fs-12{
    font-size: 12px;
}

.fs-13{
    font-size: 13px;
}


.fs-14{
    font-size: 14px;
}

.fs-15{
    font-size: 15px;
}

.fs-16{
    font-size: 16px;
}

.fs-17{
    font-size: 17px;
}

.fs-18{
    font-size: 18px;
}


.fs-20{
    font-size: 20px;
}


.fs-24{
    font-size: 24px;
}




.fw-300{
    font-weight: 300;
}

.fw-400{
    font-weight: 400;
}

.fw-500{
    font-weight: 500;
}

.fw-600{
    font-weight: 600;
}

.fw-700{
    font-weight: 700;
}

.fw-800{
    font-weight: 800;
}


.blue-primary{
    background: #0085FF;
}

@media screen and (max-width: 640px) {
    .h-logo {
        height: 40px !important;
    }

    .h-img-banner{
        height: 250px  !important;
    }

    .fs-28{
        font-size: 16px !important;
    }


    .h-banner-img-about {
        height: 150px !important;
    }



    .radius-4{
        border-radius: 4px;
    }

    .fs-17{
        font-size: 14px !important;
    }


    .fs-18{
        font-size: 15px !important;
    }

    .fs-15{
        font-size: 12px !important;
    }

    .fs-16{
        font-size: 14px !important;
    }


    .fs-20{
        font-size: 16px !important;
    }

    .h-img-card-act{
        height: 150px !important;
    }


    .w-card{
        width: 90% !important;
    }


    .fs-title{
        font-size: 22px !important;
    }

    .card-teacher{
        width: 70% !important;
        height: 100%;
        position: absolute;
        top: -50%;
        left: 0;
        box-shadow: #858585 2px 5px 3px;
        right: 0;
        margin: 0 auto;
        border-radius: 8px;
    }


    .h-img-banner-activity{
        height: 230px;
    }

    .h-img-card-school{
        height: 200px !important;
    }

    .h-banner-img{
        height: 200px;
    }

    .h-ext-img{
        height: 100px !important;
    }




}
.h-img-card-act{
    height: 200px;
}


@media screen and (max-width: 768px) {
    .h-logo {
        height: 40px;
    }

    .h-img-banner{
        height: 300px;
    }

    .h-ext-img{
        height: 80px;
    }



}

@media screen and (max-width: 1024px) {
    .h-logo {
        height: 40px;
    }

    .h-img-banner{
        height: 300px;
    }

    .h-ext-img{
        height: 100px;
    }

    .h-banner-img-about {
        height: 280px ;
    }



}




@media screen and (max-width: 1280px) {
    .h-logo {
        height: 50px;
    }

    .h-img-banner{
        height: 380px;
    }

    .fs-28{
        font-size: 22px ;
    }

    .h-img-card-act{
        height: 200px;
    }

    .fs-title{
        font-size: 22px
    }

    .w-card{
        width: 96%;
    }


    .h-ext-img{
        height: 100px;
    }

    .h-banner-img-about {
        height:220px
    }


    .h-img-card-school{
        height: 300px;
    }





    .card-teacher{
        width: 60%;
        height: 98%;
        position: absolute;
        top: -50%;
        left: 0;
        box-shadow: #858585 2px 5px 3px;
        right: 0;
        margin: 0 auto;
        border-radius: 8px;
    }


}



.h-logo {
    height: 50px ;
}


.h-flow{
    height: 20px;
}


@media screen and (min-width: 1536px) {
    .h-logo {
        height: 50px;
    }

    .fs-28{
        font-size: 28px;
    }


    .h-ext-img{
        height: 150px;
    }

    .h-banner-img-about {
        height:300px ;
    }


    .fs-title{
        font-size: 28px
    }


    .h-img-banner{
        height: 380px;
    }



    .w-card{
        width: 96%;
    }


    .card-teacher{
        width: 60%;
        height: 98%;
        position: absolute;
        top: -50%;
        left: 0;
        box-shadow: #858585 2px 5px 3px;
        right: 0;
        margin: 0 auto;
        border-radius: 8px;
    }
}


@media screen and (max-width: 1536px) {
    .h-logo {
        height: 50px;
    }

    .fs-28{
        font-size: 28px;
    }


    .h-ext-img{
        height: 150px;
    }

    .h-banner-img-about {
        height:300px ;
    }


    .fs-title{
        font-size: 28px
    }


    .h-img-banner{
        height: 380px;
    }



    .w-card{
        width: 96%;
    }


    .card-teacher{
        width: 60%;
        height: 98%;
        position: absolute;
        top: -50%;
        left: 0;
        box-shadow: #858585 2px 5px 3px;
        right: 0;
        margin: 0 auto;
        border-radius: 8px;
    }
}
